<template>
  <div class="login-index">
    <Header :typeFu="1"></Header>
    <div class="big-right">
      <div class="info-box">
        <router-view/>
      </div>
    </div>
  </div>
</template>
<script>
  /* eslint-disable */
  import Header from "@/components/Header.vue";

  export default {
    name: "loginBase",
    data() {
      return {};
    },
    components: {
      Header,
    },
    created() {
    },
    methods: {},
  };
</script>

<style lang="scss" scoped>
  @media screen and (max-width: 1500px) {
    .login-index .big-right .info-box {
      width: 32%!important;
      /*top: 60%!important;*/
    }
  }

  .login-index {
    position: relative;
    min-width: 1150px;
    background: #f8f8f8;
    min-height: 100%;
    /*width: 100%;*/

    .big-right {
      height: calc(100% - 80px);

      .info-box {
        width: 520px;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
</style>
