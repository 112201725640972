<template>
  <div class="Header" :class="type==1?'all-header':''">
    <div class="H_content com-container">
      <div class="image">
        <img v-if="type==1" :src="all_logo" alt="" @click="toHome"/>
        <img v-if="type==2" :src="system_logo" alt="" @click="toRecruit"/>
        <img v-if="type==3" :src="system_logo" alt="" @click="toRecruit"/>
        <img v-if="type==4" :src="all_logo" alt="" @click="toHome"/>
        <!--<img v-if="type==4" :src="system_logo?system_logo:all_logo" alt=""/>-->
      </div>
      <div class="right">
        <el-menu :default-active="$store.state.activeIndex"
                 @select="handleSelect"
                 mode="horizontal">
          <el-menu-item index="/">首页</el-menu-item>
          <el-menu-item index="/active">活动中心</el-menu-item>
        </el-menu>
        <el-button v-if="type==2" @click="toSignUp" class="sign-up">立即报名</el-button>
        <div class="noLogin" v-if="!isLogin">
          <span
            :class="isHover ? '' : 'is-hover'"
            @click="toLogin"
            @mouseenter="onMouserEnter(false)"
          >登录 | 注册</span>
        </div>
        <div class="alreadyLogin" v-else>
          <el-dropdown @command="handleCommand" trigger="click">
            <div class="el-dropdown-link">
              <img class="img_user" src="@/assets/img/default.svg"/>
              <span class="phone">{{ phone }}</span>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click="toCenter" command="1">
                <i class="el-icon-user"></i>个人中心
              </el-dropdown-item>
              <el-dropdown-item @click="logout" command="2">
                <i class="el-icon-switch-button"></i>退出
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {logout} from "r/index/login";
  import logoImg from "../assets/img/logo_h.png";

  export default {
    name: "Header",
    props: {
      typeFu: [String, Number],
    },
    data() {
      return {
        type: this.typeFu, // 1-baseHeader 2-营地简章的header 3-登录报名流程里的header 4-报名中心的header
        isHover: true,
        isLogin: false,
        phone: '',
        system_logo: '',
        all_logo: logoImg,
      };
    },
    created() {
      this.isLogin = !!localStorage.getItem("index-token-all");
      this.phone = localStorage.getItem("index-phone-all") || "";
      let code = this.$store.state.indexIdentity;
      this.system_logo = localStorage.getItem("system_logo" + code) || "";
    },
    methods: {
      // 导航栏操作
      handleSelect(key, keyPath) {
        this.$router.push({
          path: key,
          query: {
            ...this.$store.state.query
          }
        });
        let root = document.querySelector(":root");
        root.style.setProperty("--color", "#4FACFE");
        // let code = this.$store.state.indexIdentity;
        // window.localStorage.setItem("system_logo" + code, this.all_logo);
      },
      // 下拉菜单操作
      handleCommand(command) {
        if (command == 1) {
          let root = document.querySelector(":root");
          root.style.setProperty("--color", "#4FACFE");
          this.toCenter();
        }
        if (command == 2) {
          this.logout();
        }
      },
      // 去个人中心
      toCenter() {
        this.$router.push({
          path: "/myInfo/signUpList",
          query: {
            ...this.$store.state.query
          }
        });
      },
      // 立即报名
      toSignUp() {
        let code = this.$store.state.indexIdentity;
        let token = window.localStorage.getItem("index-token-all");
        if (!token) {
          this.$router.push({
            path: "/login",
            query: {
              ...this.$store.state.query,
              code: code
            }
          });
        } else {
          this.$router.push({
            path: "/signUp/undefined",
            query: {
              ...this.$store.state.query,
              code: code
            }
          });
        }
      },
      // 去登录页
      toLogin() {
        if (this.type == 1) {
          this.$router.push({
            path: "/baseLogin",
            query: {
              ...this.$store.state.query,
            }
          });
        } else if (this.type == 2) {
          let code = this.$store.state.indexIdentity;
          this.$router.push({
            path: "/login",
            query: {
              ...this.$store.state.query,
              code: code
            }
          });
        }
      },
      // 退出登录
      async logout() {
        const confirmResult = await this.$confirm(`确认退出登录？`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
          type: "warning",
        }).catch((err) => err);
        if (confirmResult !== "confirm") {
          return this.$message.info("您取消了退出");
        }
        await logout({});
        let previousPath = localStorage.getItem('index-previousPath');
        // 清除本地缓存 除了特殊缓存
        this.$store.commit('removeLocalStorage');
        this.isLogin = false;
        this.$message.success("退出成功");
        if (this.type == 3) {
          let code = this.$store.state.indexIdentity;
          if (this.$store.state.human == 1) {
            await this.$router.replace({
              path: "/login",
              query: {
                ...this.$store.state.query,
                code: code,
              },
            });
          } else {
            await this.$router.replace({
              path: "/" + code,
              query: {
                ...this.$store.state.query,
              },
            });
          }
        }
        if (this.type == 4) {
          if (previousPath &&
            (previousPath == '/' ||
              previousPath == '/active' ||
              previousPath.indexOf('/active/') != -1)) {
            await this.$router.replace({
              path: previousPath,
              query: {
                ...this.$store.state.query,
              },
            });
          } else {
            let code = this.$store.state.indexIdentity;
            if (this.$store.state.human == 1) {
              await this.$router.replace({
                path: "/login",
                query: {
                  ...this.$store.state.query,
                  code: code,
                },
              });
            } else {
              await this.$router.replace({
                path: "/" + code,
                query: {
                  ...this.$store.state.query,
                },
              });
            }
          }
        }
      },
      // 鼠标移入事件
      onMouserEnter(val) {
        this.isHover = val;
      },
      // 点击图片跳转到招生简章页面
      toRecruit() {
        // 清除本地缓存除了大学logo
        let code = this.$store.state.indexIdentity;
        this.$router.push({
          path: "/" + code,
          query: {
            ...this.$store.state.query,
          }
        });
      },
      // 点击图片跳转到首页
      toHome() {
        this.$router.push({
          path: "/",
          query: {
            ...this.$store.state.query,
          }
        });
      },
    },
    watch: {
      typeFu(val) {
        this.type = val;
        if (val == 2 || val == 3) {
          let code = this.$store.state.indexIdentity;
          this.system_logo = localStorage.getItem("system_logo" + code) || "";
        }
      }
    }
  };
</script>
<style lang="scss" scoped>
  @import "a/scss/common";

  .Header {
    font-family: PingFang SC;
    height: 80px;
    background-color: #ffffff;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12);

    .H_content {
      height: 100%;
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: space-between;

      .image {
        img {
          height: 30px;
          cursor: pointer;
          vertical-align: top;
        }
      }

      .right {
        display: flex;
        flex-flow: row;
        align-items: center;

        ::v-deep .el-menu {
          margin-right: 100px;
          border: 0;

          .el-menu-item {
            height: 44px;
            line-height: 44px;
            padding: 0 5px;
            margin: 18px 20px;
            color: #666666;
            font-size: 15px;
            font-family: "PingFang SC";

            &:hover {
              font-weight: bold;
              color: var(--color);
            }

            &.is-active {
              font-weight: bold;
              color: var(--color);
              border-bottom-width: 3px;
              border-bottom-color: var(--color);
            }
          }
        }

        .sign-up {
          color: #666666;
          border: 2px solid #aaaaaa;
          background-color: transparent;
          margin: 0 30px 0 0;

          &:hover {
            color: var(--color);
            border: 2px solid var(--color);
          }
        }

        .noLogin {
          flex: 1;

          span {
            font-size: 15px;
            font-weight: 500;
            color: #666666;
            line-height: 21px;
            padding: 10px 36px;
            border-radius: 4px;
            border: 2px solid transparent;

            &.is-hover {
              cursor: pointer;
              color: var(--color);
              border-color: var(--color);
            }
          }
        }

        .alreadyLogin {
          flex: 1;

          span {
            padding: 0 !important;
          }

          ::v-deep .el-dropdown {
            height: 80px;

            .el-dropdown-link {
              height: 80px;
              display: flex;
              flex-flow: row;
              align-items: center;

              .img_user {
                width: 34px;
                height: 34px;
                background-color: #d8d8d8;
                border-radius: 50%;
              }

              .phone {
                margin: 0 10px 0 12px;
              }
            }

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }

    &.all-header {
      ::v-deep .el-menu {
        .el-menu-item {
          &:hover {
            color: var(--all_color) !important;
          }

          &.is-active {
            color: var(--all_color) !important;
            border-bottom-color: var(--all_color) !important;
          }
        }
      }

      .sign-up {
        &:hover {
          color: var(--all_color) !important;
          border: 2px solid var(--all_color) !important;
        }
      }

      .noLogin {
        span {
          &.is-hover {
            color: var(--all_color) !important;
            border-color: var(--all_color) !important;
          }
        }
      }
    }
  }
</style>
